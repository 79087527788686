<template />

<script>
export default {
  name: 'SSOAuth',

  computed: {
  },
  async created() {
    await this.authSSO();
  },
  methods: {
    async authSSO() {
      await this.$store.dispatch('SSOAuth', {
        code: this.$route.query.token
      });
      
      this.$router.push({ path: `/${this.$route.params.communityId}/` }); // AQUÍ ES DONDE VA LA URL DONDE LO VAS A REDIRECCIONAR
      return '';
    },
  },
};
</script>
